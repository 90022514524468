import React from "react"
import { Link } from "gatsby"
const FontAwesomeIcon = props => {
  const className=()=>{
    return `fas fa-${props.icon} ${props.scale?`fa-${props.scale}`:""}`
  }
  return (
    <>
      {props.tooltiptext == null ? (
        <i
          className={className()}
          style={{ paddingRight: "2px" }}
        ></i>
      ) : (
        <a href={props.href||"#"} data-toggle="tooltip" title={props.tooltiptext}  onClick={props.onClick||null} >
          <i
            className={className()}
            style={{ paddingRight: "2px" }}
          ></i>
        </a>
      )}
    </>
  )
}

export default FontAwesomeIcon
