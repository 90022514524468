import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#5982a8`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
           
      <h2 style={{ margin: 0 }}>
      <FontAwesomeIcon
            icon="home"
            style={{
              color: `white`,
              textDecoration: `none`,
              paddingLeft: "10px",
              paddingRight: "20px",
              fontSize: "small",
              marginRight:"20px"
            }}
            tooltiptext="Home"
            href="/"
          />
       
          <FontAwesomeIcon
            icon="newspaper"
            style={{
              color: `white`,
              textDecoration: `none`,
              paddingLeft: "50px",
              fontSize: "small",
              marginRight:"20px"
            }}
            tooltiptext="Blog"
            href="/blog"
          />
          <FontAwesomeIcon
            icon="plus-circle"
            style={{
              color: `green`,
              textDecoration: `none`,
              paddingLeft: "10px",
              fontSize: "small",
              marginRight:"20px"
            }}
            tooltiptext="Latest Blog"
            href="/blogLatest"
          />
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            paddingLeft: "120px",
          }}
        >
          {siteTitle}
        </Link>
      </h2>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
